import axios from "axios";

const addHeaders = () => {
    return { 
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + process.env.VUE_APP_AUTH_AUTHORIZATION
        }
    }
}

const sendEmail = async (emailDto) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/email", 
    emailDto,
    addHeaders()
    );
    return result;
}

const getAccessToken = async (email, password) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/token", {
        email: email,
        password: password
    },
    { 
        headers: {
            "Granttype": "password",
            "Content-Type": "application/json",
            "Authorization": "Basic " + process.env.VUE_APP_AUTH_AUTHORIZATION
        }
    }
    );
    localStorage.setItem('access_token', result.data.access_token);
    localStorage.setItem('refresh_token', result.data.refresh_token);
    return result;
}

const refreshAccessToken = async () => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/token", {
        refresh_token: localStorage.getItem('refresh_token')
    },
    {
        headers: {
            "Granttype": "refresh_token",
            "Content-Type": "application/json",
            "Authorization": "Basic " + process.env.VUE_APP_AUTH_AUTHORIZATION
        }
    }
    );
    localStorage.setItem('access_token', result.data.access_token);
    localStorage.setItem('refresh_token', result.data.refresh_token);
    return result;
}

const signup = async (user) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/signup", user,
    addHeaders()
    );
    return result;
}

const verify = async (target, vcode) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/activate", 
    {
        target: target,
        vcode: vcode
    },
    addHeaders()
    );
    return result;
}

const sendVcode = async (target, type) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/sendvcode", 
    {
        target: target,
        type: type
    },
    addHeaders()
    );
    return result;
}

const resetPassword = async (resetPasswordDto) => {
    const result = await axios.post(process.env.VUE_APP_AUTH_SERVICE + "/resetpassword", 
    resetPasswordDto,
    addHeaders()
    );
    return result;
}


export { getAccessToken, refreshAccessToken, signup, verify, sendVcode, resetPassword, sendEmail };
